import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { LanguageService } from './services/language.service';

// other
import { DEFAULT_LANGUAGE, Language, LanguageModuleConfig, LANGUAGES } from './interfaces/language';
import { MESSAGE_FORMAT_CONFIG, MessageFormatConfig } from 'ngx-translate-messageformat-compiler';

export function messageFormatConfigFactory(languages: Language[]): MessageFormatConfig {
    return {
        // locales: languages.map(x => x.code),
    };
}


@NgModule({})

export class LanguageModule {
    static config(config: LanguageModuleConfig): ModuleWithProviders<LanguageModule> {
        return {
            ngModule: LanguageModule,
            providers: [
                LanguageService,
                {
                    provide: DEFAULT_LANGUAGE,
                    useValue: config.default,
                },
                {
                    provide: LANGUAGES,
                    useValue: config.languages,
                },
                {
                    provide: MESSAGE_FORMAT_CONFIG,
                    useFactory: messageFormatConfigFactory,
                    deps: [LANGUAGES],
                },
            ],
        };
    }
}
