<div>
    <div class="modal-header sticky-top">
        <span class="cart-header">{{ "TEXT_MOBILE_INDICATOR_CART" | translate }}</span>
    </div>
    <div class="modal-scrollable-content">
        <ng-container *ngIf="cart.items.length <= 0">
            <div class="block-empty">
                <div class="block-empty__body">
                    <h1 class="block-empty__title">{{ 'HEADER_SHOPPING_CART_EMPTY_TITLE'|translate }}</h1>
                    <div
                        class="block-empty__message"
                        [innerHTML]="'HEADER_SHOPPING_CART_EMPTY_SUBTITLE'|translate">
                    </div>
                    <div class="block-empty__action mb-2">
                        <a routerLink="/" class="btn btn-primary btn-sm py-2 px-4" (click)="cart.close()">{{ 'BUTTON_GO_TO_HOMEPAGE'|translate }}</a>
                    </div>
                    <app-icon icon="cart-empty"></app-icon>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="cart.items.length > 0">
            <div class="block">
                <div class="cart">
                    <app-cart-items></app-cart-items>
                </div>
                <div class="cart-continue__shopping">
                    <button class="btn btn-primary btn-sm py-3 px-lg-4" (click)="cart.close()">{{'TEXT_CART_CONTINUE' | translate}}</button>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="cart__totals sticky-bottom">
        <div class="card">
            <div class="card-footer__body">
                <table>
                    <thead *ngIf="(cart.selectedTotals$|async)?.length">
                    <tr>
                        <th>{{ 'TABLE_SUBTOTAL'|translate }}</th>
                        <td>{{ ((cart.selectedSubtotal$|async) || 0)|currencyFormat }}</td>
                    </tr>
                    </thead>
                    <tbody *ngIf="(cart.selectedTotals$|async)?.length">
                    <tr *ngFor="let total of cart.selectedTotals$|async">
                        <th>{{ 'TABLE_TOTAL_' + total.title|translate }}</th>
                        <td>
                            {{ total.price|currencyFormat }}
                            <div *ngIf="total.type === 'shipping'">
                                <a href=""
                                   (click)="$event.preventDefault()">{{ 'LINK_CALCULATE_SHIPPING'|translate }}</a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="footer-total">
                        <th>{{ 'TABLE_TOTAL'|translate }}</th>
                        <td>{{ ((cart.selectedTotal$|async) || 0)|currencyFormat }}</td>
                    </tr>
                    </tfoot>
                </table>

                <a
                    class="btn btn-primary btn-xl btn-block"
                    (click)="cart.close()"
                    [class.disabled]="!(cart.selectedItems$|async)?.length"
                    [routerLink]="url.checkout()|localize"
                >
                    {{ 'BUTTON_PROCEED_TO_CHECKOUT'|translate }}
                </a>
            </div>
        </div>
    </div>
</div>

