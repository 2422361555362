import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
    private navigationStart$ = new BehaviorSubject<boolean>(false);

    constructor(private router: Router) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            // startWith(this.router),
        ).subscribe(() => {
            this.setStart();
        });
    }

    setStart() {
        this.navigationStart$.next(true);
    }

    get isNavigationStart() {
        return this.navigationStart$.value;
    }
}
