import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateUrlService {

    public currentUrl = '';
    private _currentUrl = new BehaviorSubject<string>('');
    private _currentLang = new BehaviorSubject<string>('');
    public currentUrl$ = this._currentUrl.asObservable();
    public currentLang$ = this._currentLang.asObservable();

    constructor(
        private localizeRouterService: LocalizeRouterService,
        private router: Router,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd), startWith(this.router),
        ).subscribe(() => {
            this._setCurrentUrl();
            this._setCurrentLang();
        });
    }

    private _setCurrentUrl(): void {
        this.currentUrl = this.router.url
            .replace('/' + this.localizeRouterService.parser.currentLang, '')
            .split('?')[0];
        this._currentUrl.next(this.currentUrl);
    }

    private _setCurrentLang(): void {
        this._currentLang.next(this.localizeRouterService.parser.currentLang);
    }
}
