<div class="cart__table cart-table">
    <table class="cart-table__table">
        <tbody class="cart-table__body">
        <tr *ngFor="let item of items; index as i" class="cart-table__row" appRemoveFromCart
            #removeFromCart="removeFromCart">
            <td class="cart-table__column cart-table__column--checkbox">
                <mat-checkbox class="example-margin"
                              [checked]="item.selectForCheckout"
                              (change)="update(i, $event.checked)">
                </mat-checkbox>
            </td>
            <td class="cart-table__column cart-table__column--number">
                {{i + 1}}
            </td>
            <td class="cart-table__column cart-table__column--image">
                <div class="image image--type--product">
                    <a [routerLink]="url.product(item.product)" class="image__body">
                        <img class="image__tag" [src]="item.product|getProductImage" alt="">
                    </a>
                </div>
            </td>
            <td class="cart-table__column cart-table__column--product">
                <a [routerLink]="url.product(item.product)" class="cart-table__product-name">{{ item.product.name }}</a>
                <ul *ngIf="item.options.length" class="cart-table__options">
                    <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                </ul>
                <ul *ngIf="item.product.sku" class="cart-table__options">
                    <li>{{ item.product.sku }}</li>
                </ul>
            </td>
            <td class="cart-table__column cart-table__column--quantity" [attr.data-title]="'TABLE_QUANTITY'|translate">
                <app-input-number
                    *ngIf="quantityControls[i]"
                    class="cart-table__quantity"
                    [min]="1"
                    [formControl]="quantityControls[i]"
                    [old]="item.quantityOld"
                >
                </app-input-number>
            </td>
            <td class="cart-table__column cart-table__column--total" [attr.data-title]="'TABLE_TOTAL'|translate">
                {{ item.product.price * item.quantity|currencyFormat }}
            </td>
            <td class="cart-table__column cart-table__column--remove">
                <button
                    type="button"
                    class="cart-table__remove btn btn-sm btn-icon btn-muted"
                    [class.btn-loading]="removeFromCart.inProgress"
                    (click)="removeFromCart.remove(item)"
                >
                    <app-icon icon="cross-12"></app-icon>
                </button>
            </td>
        </tr>
        </tbody>
        <tfoot class="cart-table__foot">
        <tr>
            <td colspan="4" class="cart-table__foot-total">
                <div class="total">
                    {{ 'TEXT_TOTAL_CART'|translate }}:
                    <span>{{ (cart.selectedTotal$|async) ?? 0 |currencyFormat }}</span>
                </div>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
