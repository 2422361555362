<span class="pargo-footer-contacts__title">{{ 'TEXT_CONTACTS'|translate }}</span>

<address class="">
    <div *ngFor="let item of data?.phones"
         class="pargo-footer-contacts__item">
        <app-icon [icon]="'phone-20'"></app-icon>
        <span *ngIf="!data?.isB2B; else phoneB2B">{{item.name}}</span>
        <ng-template #phoneB2B>
            <div>{{item.name}}</div>
        </ng-template>
    </div>
    <div *ngFor="let item of data?.emails"
         class="pargo-footer-contacts__item">
        <app-icon [icon]="'mail-20'"></app-icon>
        <span>{{item.name}}</span>
    </div>
    <div *ngFor="let item of data?.pickupPoints"
         class="pargo-footer-contacts__item">
        <app-icon [icon]="'geo-20'"></app-icon>
        <span>{{pointName(item)}}</span>
    </div>
</address>
