<ng-container>
    <div class="topbar__item-text">
        <app-clients></app-clients>
    </div>
    <div class="topbar__item-text topbar__separator"></div>
    <div class="topbar__item-text">
        <a class="topbar__link" routerLink="{{'/site/cooperation'|localize}}">
            {{ 'LINK_WHOLESALE'|translate|uppercase }}
        </a>
    </div>
    <div class="topbar__item-text topbar__separator"></div>
    <div class="topbar__item-text">
        <a class="topbar__link" routerLink="{{'/site/contact-us'|localize}}">
            {{ 'LINK_CONTACTS'|translate|uppercase }}
        </a>
    </div>
    <div class="topbar__item-spring"></div>
</ng-container>
<ng-container>
    <div class="topbar__item-text">
        <app-contacts [data]="contact.contactData$|async"></app-contacts>
    </div>
    <app-language-selector></app-language-selector>
</ng-container>
