import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountApi, CommonsApi, ShopApi, VehicleApi, CheckoutApi, ContentApi } from '../base';
import { RestAccountApi } from './rest-account.api';
import { RestShopApi } from './rest-shop.api';
import { RestVehicleApi } from './rest-vehicle.api';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RestCommonsApi } from './rest-commons.api';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { RestCheckoutApi } from './rest-checkout.api';
import { RestContentApi } from './rest-content.api';
import { LangInterceptor } from './interceptors/lang.interceptor';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: AccountApi, useClass: RestAccountApi },
        { provide: ShopApi, useClass: RestShopApi },
        { provide: VehicleApi, useClass: RestVehicleApi },
        { provide: CommonsApi, useClass: RestCommonsApi },
        { provide: CheckoutApi, useClass: RestCheckoutApi },
        { provide: ContentApi, useClass: RestContentApi },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    ],
})
export class RestApiModule {
}
