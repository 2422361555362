import { Component, HostBinding, Input } from '@angular/core';
import { ContactService } from '../../../../services/contact.service';

export type TopbarLayout = 'spaceship-start'|'spaceship-end'|'classic'|'pargo';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
    @Input() layout!: TopbarLayout;

    @HostBinding('class.topbar') classTopbar = true;

    @HostBinding('class.topbar--pargo') get classTopbarPargo(): boolean {
        return this.layout === 'pargo';
    }

    constructor(
        public contact: ContactService,
    ) { }
}
