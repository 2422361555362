import { Injectable } from '@angular/core';
import { Category, ShopCategory } from '../interfaces/category';
import { Address } from '../interfaces/address';
import { Order } from '../interfaces/order';
import { Product } from '../interfaces/product';
import { Brand } from '../interfaces/brand';
import { BaseVehicle } from '../interfaces/vehicle';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor() { }

    home(): string {
        return '/';
    }

    shop(): string {
        return '/shop';
    }

    category(category: Category): string {
        if (category.type === 'shop') {
            return this.shopCategory(category);
        }

        return '/';
    }

    categoryPicker(category: Category, carBrand?: BaseVehicle, carModel?: BaseVehicle, productBrand?: Brand): any {
        if(carBrand && carModel && productBrand) {
            return `/shop/car/${carBrand.slug}/${carModel.slug}/brand/${productBrand.slug}/category/${category.slug}`;
        } else if (carBrand && productBrand) {
            return `/shop/car/${carBrand.slug}/brand/${productBrand.slug}/category/${category.slug}`;
        } else if (carBrand && carModel) {
            return `/shop/car/${carBrand.slug}/${carModel.slug}/category/${category.slug}`;
        } else if (carBrand) {
            return `/shop/car/${carBrand.slug}/category/${category.slug}`;
        } else if (productBrand) {
            return `/shop/brand/${productBrand.slug}/category/${category.slug}`;
        } else {
            return `/shop/category/${category.slug}`;
        }
    }

    shopCategory(category: ShopCategory): string {
        return `/shop/category/${category.slug}`;
    }

    allProducts(): string {
        return this.shop();
    }

    targetProducts(keyword: string, category?: Category): string {
        if (category) {
            return `/shop/target/${keyword}/${category.slug}`;
        }
        return `/shop/target/${keyword}`;
    }

    categorySidebar(category: Category, keyword?: string, targetKeyword?: string): string {
        if (keyword) {
            return `/shop/search/c/${category.slug}/keyword=${encodeURIComponent(keyword)}`;
        }
        if (targetKeyword) {
            return `/shop/target/${targetKeyword}/c/${category.slug}`;
        }
        return `/shop/category/${keyword}`;
    }

    categorySidebarAll(keyword?: string, targetKeyword?: string): string {
        if (keyword) {
            return `/shop/search/keyword=${encodeURIComponent(keyword)}`;
        }
        if (targetKeyword) {
            return `/shop/target/${targetKeyword}`;
        }
        return `/shop`;
    }

    product(product: Product): string {
        if (product.crossDummy) {
            return `/shop/products/d/${product.slug}`;
        }
        return `/shop/products/${product.slug}`;
    }

    search(term: string): string {
        return `/shop/search/keyword=${encodeURIComponent(term)}`;
    }

    brand(brand: Brand, category?: Category | null, carBrand?: BaseVehicle, carModel?: BaseVehicle): string {
        if (category && carBrand && carModel) {
            return `/shop/car/${carBrand.slug}/${carModel.slug}/brand/${brand.slug}/category/${category.slug}`;
        } else if (carBrand && carModel) {
            return `/shop/car/${carBrand.slug}/${carModel.slug}/brand/${brand.slug}`;
        } else if (category && carBrand) {
            return `/shop/car/${carBrand.slug}/brand/${brand.slug}/category/${category.slug}`;
        } else if (carBrand) {
            return `/shop/car/${carBrand.slug}/brand/${brand.slug}`;
        } else if (category) {
            return `/shop/brand/${brand.slug}/category/${category.slug}`;
        }
        return `/shop/brand/${brand.slug}`;
    }

    car(brand: BaseVehicle, model?:BaseVehicle, category?: Category | null, productBrand?: Brand): string {
        if(model && productBrand && category) {
            return `/shop/car/${brand.slug}/${model.slug}/brand/${productBrand.slug}/category/${category.slug}`;
        } else if (model && productBrand) {
            return `/shop/car/${brand.slug}/${model.slug}/brand/${productBrand.slug}`;
        } else if (model && category) {
            return `/shop/car/${brand.slug}/${model.slug}/category/${category.slug}`;
        } else if (productBrand && category) {
            return `/shop/car/${brand.slug}/brand/${productBrand.slug}/category/${category.slug}`;
        } else if (model) {
            return `/shop/car/${brand.slug}/${model.slug}`;
        } else if (category) {
            return `/shop/car/${brand.slug}/category/${category.slug}`;
        } else if (productBrand) {
            return `/shop/car/${brand.slug}/brand/${productBrand.slug}`;
        } else {
            return `/shop/car/${brand.slug}`;
        }
    }

    address(address: Address): string {
        return `/account/addresses/${address.id}`;
    }

    order(order: Order): string {
        return `/account/orders/${order.id}`;
    }

    orders(): string {
        return '/account/orders';
    }

    cart(): string {
        return '/shop/cart';
    }

    checkout(): string {
        return '/shop/checkout';
    }

    // login(): string {
    //     return '/account/login';
    // }

    contacts(): string {
        return '/site/contact-us-v2';
    }

    vehicle(): string {
        return '/vehicle';
    }
}
