import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../interfaces/product';

@Pipe({
    name: 'getProductImage',
    standalone: true,
})
export class GetProductImagePipe implements PipeTransform {
    transform(value: Product, num: number = 0): string {
        const images = value?.images || [];

        if (!images.length) return 'assets/images/noimage.svg';

        return images[num] || images[0];
    }
}
