<app-icon [icon]="'lang-14'"></app-icon>

<ng-container *ngFor="let item of language.all; let last = last">
    <a
            class="language-selector__button"
            [class.language-selector__button-active]="language.current === item"
            type="button"
            [routerLink]="langUrl(item, translateUrl.currentUrl)"
            (click)="language.set(item.code); menu.close()"
    >
        <span>{{ item.shortName }}</span>
    </a>
    <span *ngIf="!last"> | </span>
</ng-container>
