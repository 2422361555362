import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';
import { SignFormComponent } from '../sign-form/sign-form.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        ModalModule,
        IconComponent,
        CommonModule,
        SignFormComponent,
    ],
})
export class LoginComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();

    @ViewChild('modal', { static: false }) modal?: ModalDirective;

    loadForm = false;

    constructor(public loginService: LoginService) {}

    ngOnInit(): void {
        this.loginService.isVisible$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (value) {
                    this.open();
                } else {
                    if (this.modal?.isShown) {
                        this.modal?.hide();
                    }
                }
            });
    }

    open() {
        this.loadForm = true;
        this.modal?.show();
    }

    onClose() {
        this.loadForm = false;
        this.loginService.close();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
