<div class="btn-scroll-to-top"
     [class.btn-scroll-to-top--hiding]="isHiding"
     [class.btn-scroll-to-top--hide]="isHide"
     (click)="scrollToTop()"
>
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
        <circle cx="26.5" cy="26.5" r="26" fill="white" stroke="#E3E3E3"/>
        <path d="M26.4977 25.0162L30.6129 28.8369C30.726 28.9421 30.8771 29 31.0383 29C31.1995 29 31.3506 28.9421 31.4638 28.8369L31.8242 28.5021C31.9376 28.3971 32 28.2567 32 28.1071C32 27.9574 31.9376 27.8172 31.8242 27.7121L26.9246 23.1629C26.811 23.0574 26.6593 22.9996 26.4979 23C26.3359 22.9996 26.1843 23.0573 26.0706 23.1629L21.1758 27.7078C21.0624 27.813 21 27.9532 21 28.1029C21 28.2525 21.0624 28.3928 21.1758 28.498L21.5362 28.8327C21.7708 29.0505 22.1527 29.0505 22.3872 28.8327L26.4977 25.0162Z"
              fill="#242424"/>
    </svg>
</div>
