import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../modules/language/services/language.service';

@Injectable()
export class LangInterceptor implements HttpInterceptor {

    constructor(private language: LanguageService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.language.current) {
            const modifiedParams = request.params.set('lan', this.language.current.code);
            const modifiedReq = request.clone({ params: modifiedParams });
            return next.handle(modifiedReq);
        }
        return next.handle(request);
    }
}
