import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-scroll-to-top-button',
    templateUrl: './scroll-to-top-button.component.html',
    styleUrls: ['./scroll-to-top-button.component.scss'],
    standalone: true,
})
export class ScrollToTopButtonComponent implements OnInit {
    showScrollToTopButton = false;
    public isHide = true;
    public isHiding = false;

    constructor(
        private zone: NgZone,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                fromEvent(window, 'scroll').subscribe(() => {
                    const scrollPosition = window.scrollY;
                    if (scrollPosition > 200) {
                        this.show();
                    } else {
                        this.hide();
                    }
                });
            });
        }
    }

    show() {
        if (this.isHide && !this.isHiding) {
            this.zone.run(() => {
                this.isHide = false;
                this.isHiding = true;

                setTimeout(() => {
                    this.isHiding = false;
                }, 300);
            });
        }
    }

    hide() {
        if (!this.isHide && !this.isHiding) {
            this.zone.run(() => {
                this.isHiding = true;

                setTimeout(() => {
                    this.isHide = true;
                    this.isHiding = false;
                }, 300);
            });
        }
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

}
