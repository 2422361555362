<div class="mobile-menu__backdrop" (click)="menu.close()"></div>
<div class="mobile-menu__body" #body>
    <!--    <button class="mobile-menu__close" type="button" (click)="menu.close()">-->
    <!--        <app-icon icon="cross-12"></app-icon>-->
    <!--    </button>-->

    <div class="d-flex justify-content-between align-items-center p-2">
        <a
            class="mobile-menu__panel-header__logo"
            routerLink="{{ '/' | localize }}"
            (click)="closeMenu()"
        >
            <app-mobile-logo></app-mobile-logo>
        </a>
        <app-language-selector></app-language-selector>
    </div>

    <div class="mobile-menu__conveyor" [style.transform]="'translateX(-' + currentLevel * 100 + '%)'" #conveyor>
        <app-mobile-menu-panel label="Menu">
            <app-mobile-menu-links [items]="categoriesContent" (itemClick)="onLinkClick($event)"></app-mobile-menu-links>
            <app-mobile-menu-indicators></app-mobile-menu-indicators>

            <div class="mobile-menu__links-container">
                <div class="mobile-menu__links-container--item">
                    <app-clients [isMenu]="true"></app-clients>
                </div>
                <div class="mobile-menu__links-container--item" (click)="closeMenu()">
                    <a class="mobile-menu__links-container--item-link"
                       routerLink="{{'/site/cooperation'|localize}}">
                        {{ 'LINK_WHOLESALE'|translate }}
                    </a>
                </div>
                <div class="mobile-menu__links-container--item" (click)="closeMenu()">
                    <a class="mobile-menu__links-container--item-link"
                       routerLink="{{'/site/contact-us'|localize}}">
                        {{ 'LINK_CONTACTS'|translate }}
                    </a>
                </div>
            </div>

            <div class="mobile-menu__contact-links">
                <div class="mobile-menu__contact-links--part">
<!--                    <div *ngFor="let item of company.contacts.viber" class="mobile-menu__contact-links&#45;&#45;part-item">-->
<!--                        <a class="mobile-menu__contact-links&#45;&#45;part-item-link" routerLink="{{item}}" target="_blank">-->
<!--                            <span class="mobile-menu__contact-links&#45;&#45;part-item-link-icon"><app-icon icon="viber-20"></app-icon></span>-->
<!--                            <span class="mobile-menu__contact-links&#45;&#45;part-item-link-text">{{ 'TEXT_VIBER'|translate }}</span>-->
<!--                        </a>-->
<!--                    </div>-->
                    <ng-container *ngFor="let item of (contact.contactData$|async)?.messengers">
                        <div *ngIf="item.category === 'telegram'" class="mobile-menu__contact-links--part-item">
                            <a class="mobile-menu__contact-links--part-item-link" [href]="item.contact" target="_blank">
                                <span class="mobile-menu__contact-links--part-item-link-icon"><app-icon icon="telegram-20"></app-icon></span>
                                <span class="mobile-menu__contact-links--part-item-link-text">{{ item.name }}</span>
                            </a>
                        </div>
                    </ng-container>

                </div>
                <span class="mobile-menu__contact-links--divider"></span>
                <div class="mobile-menu__contact-links--part">
                    <ng-container *ngFor="let item of (contact.contactData$ | async)?.phones">
                        <div class="mobile-menu__contact-links--part-item">
                            <ng-container *ngIf="!(contact.contactData$ | async)?.isB2B; else phoneB2B">
                                <span class="mobile-menu__contact-links--part-item-link-icon"><app-icon icon="phone-20"></app-icon></span>
                                <a href="tel:{{item.contact}}">{{item.name}}</a>
                            </ng-container>
                            <ng-template #phoneB2B>
                                <a class="mobile-menu__contact-links--part-item-link d-flex align-items-center" href="tel:{{item.contact}}">
                                    <span class="mobile-menu__contact-links--part-item-link-icon"><app-icon icon="phone-20"></app-icon></span>
                                    <div class="mobile-menu__contact-links--part-item-link-text">{{item.name}}</div>
                                </a>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="mobile-menu__schedule">
                <span *ngFor="let item of company.contacts.hours">{{item}}</span>
            </div>
            <div class="mobile-menu__spring"></div>

            <button *ngIf="(loginService.login$|async) === false; else logout"
                    class="mobile-menu__login-button" (click)="loginService.open(); closeMenu()">
                <span>{{'BUTTON_LOGIN'|translate|uppercase}}</span>
                <span class="mobile-menu__login-button__secondary">B2B/Роздріб</span>
            </button>
            <ng-template #logout>
                <button class="mobile-menu__login-button p-2" (click)="loginService.logout(); closeMenu()">
                    <span>{{'LINK_ACCOUNT_LOGOUT'|translate|uppercase}}</span>
                </button>
            </ng-template>

        </app-mobile-menu-panel>

        <ng-container #panelsContainer></ng-container>
    </div>
</div>
