import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CartService } from '../../../../services/cart.service';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { UrlService } from '../../../../services/url.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CartItem } from '../../../../api';
import { RemoveFromCartDirective } from '../../directives/remove-from-cart.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterLink } from '@angular/router';
import { GetProductImagePipe } from '../../pipes/get-product-image.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberComponent } from '../input-number/input-number.component';
import { CurrencyModule } from '../../../currency/currency.module';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-cart-items',
  standalone: true,
    imports: [CommonModule, RemoveFromCartDirective, MatCheckboxModule, RouterLink, GetProductImagePipe, TranslateModule, InputNumberComponent, ReactiveFormsModule, CurrencyModule, IconComponent],
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent implements OnInit, OnDestroy{
    private destroy$: Subject<void> = new Subject();

    items: CartItem[] = [];
    quantityControls: UntypedFormControl[] = [];
    updating = false;
    private subscriptions: Subscription[] = [];

    @HostBinding('class.cart-items') classCartItems = true;

    @Input() selected = false;

    constructor(
        public cart: CartService,
        public url: UrlService,
    ) {}

    ngOnInit(): void {
        const cartItems$ = this.selected ? this.cart.selectedItems$ : this.cart.items$;
        cartItems$.pipe(takeUntil(this.destroy$)).subscribe(items => {
            this.items = items;
            this.quantityControls = items.map(item => new UntypedFormControl(item.quantity));

            this.subscriptions.forEach(subscription => subscription.unsubscribe());
            this.subscriptions = [];

            this.quantityControls.forEach((control, index) => {
                const subscription = control.valueChanges
                    .pipe(debounceTime(500))
                    .subscribe(newValue => {
                        this.update();
                    });
                this.subscriptions.push(subscription);
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    update(index?: number, selected?: boolean): void {
        const entries = [];

        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const quantityControl = this.quantityControls[i];

            if (item.quantity !== quantityControl.value || index === i) {
                if (index !== undefined && selected !== undefined) {
                    item.selectForCheckout = selected;
                }
                entries.push({
                    item,
                    quantity: quantityControl.value,
                });
            }
        }

        if (entries.length <= 0) {
            return;
        }

        this.updating = true;
        this.cart.update(entries).pipe(takeUntil(this.destroy$)).subscribe({
            complete: () => this.updating = false,
        });
    }
}
