import { Component, HostBinding, Input } from '@angular/core';
import { Link } from '../../../../interfaces/link';

@Component({
    selector: 'app-links-pargo',
    templateUrl: './links-pargo.component.html',
    styleUrls: ['./links-pargo.component.scss'],
})
export class LinksPargoComponent {

    isOpen: boolean = false;

    @Input() header: string = '';

    @Input() links: Link[] = [];

    @HostBinding('class.pargo-footer-links') classFooterLinks = true;

    @HostBinding('class.pargo-footer-links--open') get classPargoFooterLinksOpen() {
        return this.isOpen;
    }

    constructor() { }

    onClick() {
        this.isOpen = !this.isOpen;
    }
}
