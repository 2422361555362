import { Observable } from 'rxjs';
import { ModalPage, SitePage } from '../../interfaces/site-page';
import { PostList } from '../../interfaces/list';
import { Post } from '../../interfaces/post';
import { ContactData } from '../../interfaces/contact';
import { SEOText } from '../../interfaces/seo-text';

export abstract class ContentApi {
    abstract getPage(slug: string): Observable<SitePage>;

    abstract getBlog(page?: number, limit?: number): Observable<PostList>;

    abstract getPost(slug: string): Observable<Post>;

    abstract getContactData(): Observable<ContactData>;

    abstract getSeoText(index: string): Observable<SEOText>;

    abstract getModalContent(slug: string): Observable<ModalPage>;
}
