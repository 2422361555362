import {
    Component,
    HostBinding,
    HostListener, Input,
} from '@angular/core';
import { company } from '../../../../../data/company';
import { ContactData } from '../../../../interfaces/contact';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {

    isOpen = false;

    @HostBinding('class.contacts') classDepartments = true;

    @HostBinding('class.contacts--open') get classDepartmentsOpen() {
        return this.isOpen;
    }

    @HostListener('click') handleOnClick() {
        this.onClick();
    }

    @HostListener('mouseenter') handleOnMouseEnter() {
        this.onMouseEnter();
    }

    @HostListener('mouseleave') handleOnMouseLeave() {
        this.onMouseLeave();
    }

    company = company;

    @Input() data: ContactData | undefined | null;

    constructor() {
    }

    onClick() {
        this.isOpen = !this.isOpen;
    }

    onItemClick(): void {
        // this.isOpen = false;
    }

    onMouseLeave(): void {
        this.isOpen = false;
    }

    onMouseEnter(): void {
        this.isOpen = true;
    }
}
