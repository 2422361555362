import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapService {

  private loaded = false;

  constructor(private readonly httpClient: HttpClient) {}

  load(): Observable<boolean> {
    return !this.loaded
        ? this.httpClient
            .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapKey}`, 'callback')
            .pipe(
                finalize(() => (this.loaded = true)),
                map(() => true),
                catchError((err) => {
                    // console.log(err);
                    return of(false);
                }),
            )
        : of(this.loaded);
  }
}
