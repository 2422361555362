import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { LoginService } from '../../../../services/login.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from '../../../checkbox/checkbox.module';
import { IconComponent } from '../icon/icon.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HasErrorPipe } from '../../pipes/has-error.pipe';
import { IsInvalidPipe } from '../../pipes/is-invalid.pipe';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { SignInFormComponent } from '../sign-in-form/sign-in-form.component';
import { SignUpFormComponent } from '../sign-up-form/sign-up-form.component';

@Component({
    selector: 'app-sign-form',
    templateUrl: './sign-form.component.html',
    styleUrls: ['./sign-form.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        CommonModule,
        FontAwesomeModule,
        CheckboxModule,
        RouterLink,
        IconComponent,
        NgxMaskDirective,
        HasErrorPipe,
        IsInvalidPipe,
        LocalizeRouterModule,
        SignInFormComponent,
        SignUpFormComponent,
    ],
    providers: [provideNgxMask()],
})

export class SignFormComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    public readonly tabSignIn: string = 'tabSignIn';
    public readonly tabSignUp: string = 'tabSignUp';

    public currentTab: string | undefined;

    @HostBinding('class.sign-form') classSignForm = true;

    constructor(
        private router: Router,
        public modalService: LoginService,
    ) {
    }

    ngOnInit(): void {
        this.setCurrentTab(this.tabSignIn);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setCurrentTab(tabName: string) {
        this.currentTab = tabName;
    }

    loginSuccess() {
        this.modalService.close();
        this.router.navigate([this.router.url], {
            replaceUrl: true,
            skipLocationChange: false,
        }).then();
    }
}
