import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../../../services/cart.service';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UrlService } from '../../../../services/url.service';
import { CartItem } from '../../../../api';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { CurrencyModule } from '../../../currency/currency.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CartItemsComponent } from '../cart-items/cart-items.component';
import {IconComponent} from "../icon/icon.component";

@Component({
    selector: 'app-cart-form',
    templateUrl: './cart-form.component.html',
    styleUrls: ['./cart-form.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        CurrencyModule,
        LocalizeRouterModule,
        NgForOf,
        NgIf,
        RouterLink,
        TranslateModule,
        CartItemsComponent,
        IconComponent,
    ],
})
export class CartFormComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    items: CartItem[] = [];
    quantityControls: UntypedFormControl[] = [];

    constructor(
        public cart: CartService,
        public url: UrlService,
    ) {
    }

    ngOnInit(): void {

        this.cart.items$.pipe(takeUntil(this.destroy$)).subscribe(items => {
            this.items = items;
            this.quantityControls = items.map(item => new UntypedFormControl(item.quantity));
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
