import { Component, HostBinding, Input } from '@angular/core';
import { ContactData, PickupPoint } from '../../../../interfaces/contact';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-contacts-pargo',
    templateUrl: './contacts-pargo.component.html',
    styleUrls: ['./contacts-pargo.component.scss'],
})
export class ContactsPargoComponent {
    @HostBinding('class.pargo-footer-contacts') classFooterContacts = true;

    @Input() data: ContactData | undefined | null;

    constructor(private translate: TranslateService) { }

    pointName(point: PickupPoint): string {
        return point.partner
            ? `${point.address} (${this.translate.instant('TEXT_PARTNER_WAREHOUSE')})`
            : point.address;
    }
}
