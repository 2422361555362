<h2 *ngIf="showHeader" class="sign-form-content__title">
    {{ "HEADER_LOGIN" | translate | uppercase }}
</h2>
<form [formGroup]="loginForm">
    <div
        *ngIf="loginForm.errors && loginForm.errors['server']"
        class="alert alert-sm alert-danger"
    >
        {{ (loginForm.errors && loginForm.errors["server"]) || "" | translate }}
    </div>
    <div class="form-group">
        <label class="sign-form-label" for="signin-email">{{
                "INPUT_EMAIL_ADDRESS_LABEL" | translate
            }}</label>
        <input
            id="signin-email"
            type="email"
            class="form-control"
            [class.is-invalid]="loginForm.controls['email'] | isInvalid"
            placeholder="customer@example.com"
            formControlName="email"
        />
        <div class="invalid-feedback">
            <div *ngIf="loginForm.controls['email'] | hasError : 'required'">
                {{ "ERROR_FORM_REQUIRED" | translate }}
            </div>
            <div
                *ngIf="loginForm.controls['email'] | hasError : 'email'">
                {{ "ERROR_FORM_INCORRECT_EMAIL" | translate }}
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="sign-form-label" for="signin-password">
            {{"INPUT_PASSWORD_LABEL" | translate}}</label>
        <div
            class="sign-form__password-container"
            [class.is-invalid]="loginForm.controls['password'] | isInvalid"
        >
            <input
                id="signin-password"
                [type]="showSignInPassword ? 'text' : 'password'"
                class="form-control"
                [class.is-invalid]="loginForm.controls['password'] | isInvalid"
                [placeholder]="'INPUT_PASSWORD_PLACEHOLDER' | translate"
                formControlName="password"
            />
            <button
                type="button"
                class="sign-form__toggle-password"
                (click)="toggleSignInPasswordVisibility()"
            >
                <fa-icon
                    *ngIf="!showSignInPassword"
                    [icon]="faEye"
                ></fa-icon>
                <fa-icon
                    *ngIf="showSignInPassword"
                    [icon]="faEyeSlash"
                ></fa-icon>
            </button>
        </div>
        <div class="invalid-feedback">
            <div *ngIf="loginForm.controls['password'] | hasError : 'required'">
                {{ "ERROR_FORM_REQUIRED" | translate }}
            </div>
        </div>
    </div>
    <div class="form-group sign-form-row justify-content-end">
        <div class="form-text sign-form-link">
            <a (click)="modalService.close()" [routerLink]="'/forgot-password' | localize">
                {{ "LINK_FORGOT_PASSWORD" | translate }}
            </a>
        </div>
    </div>
    <div class="form-group mb-0">
        <button
            type="submit"
            class="btn btn-primary mt-4 mb-4 sign-form__submit-button"
            [class.btn-loading]="loginInProgress"
            (click)="login()"
        >
            {{ "BUTTON_LOGIN" | translate | uppercase }}
        </button>
    </div>
</form>
<div class="sign-form__divider">
    {{ "TEXT_OR_SIGN_IN" | translate | uppercase }}
</div>
<div class="sign-form__service-buttons">
    <!--                <a href="/">-->
    <!--                    <app-icon [icon]="'google-23'"></app-icon>-->
    <!--                </a>-->
    <asl-google-signin-button
        type="standard"
        size="medium"
        shape="pill"
        theme="outline"
        logo_alignment="center"
    ></asl-google-signin-button>
    <!--                <a href="/">-->
    <!--                    <app-icon [icon]="'facebook-23'"></app-icon>-->
    <!--                </a>-->
</div>
