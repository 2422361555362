import { Component, OnInit } from '@angular/core';
import { UrlService } from '../../services/url.service';
import { RouteMetaService } from '../../services/route-meta.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
    constructor(
        public url: UrlService,
        private meta: RouteMetaService,
    ) { }

    ngOnInit(): void {
        this.meta.update({
            title: this.meta.generateTitle('404', false),
            keywords: [],
            description: '',
        });
    }
}
