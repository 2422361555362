import {
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { LanguageService } from '../../../language/services/language.service';
import { TranslateUrlService } from '../../../../services/translate-url.service';
import { Language } from '../../../language/interfaces/language';
import { MobileMenuService } from '../../../../services/mobile-menu.service';
import { IconComponent } from '../icon/icon.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    standalone: true,
    imports: [
        IconComponent,
        RouterLink,
        CommonModule,
    ],
})

export class LanguageSelectorComponent {

    @HostBinding('class.language-selector') classLanguageSelector = true;

    constructor(
        public translateUrl: TranslateUrlService,
        public menu: MobileMenuService,
        protected language: LanguageService,
    ) {}

    langUrl(language: Language, path: string): string {
        if(language == this.language.default) {
            return path;
        }
        return `/${language.code}${path}`;
    }
}
