import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppBrowserModule } from './app/app-browser.module';
import { environment } from './environments/environment';

Sentry.init({
    dsn: 'https://bccd3cb379207a4f3817b45342afe042@o4504174481244160.ingest.us.sentry.io/4507888089890816',
    integrations: [],
});

if (environment.production) {
    enableProdMode();
}

function bootstrap() {
    platformBrowserDynamic().bootstrapModule(AppBrowserModule)
        .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
