import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { WishlistService } from '../../services/wishlist.service';
import { AccountApi } from '../../api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';
import { UrlService } from '../../services/url.service';
import { LoginService } from '../../services/login.service';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    userName$: Observable<string | null> = this.account.user$.pipe(map(x => x ? `${x.firstName} ${x.lastName ? x.lastName : ''}` : null));

    isPlatformBrowser = isPlatformBrowser(this.platformId);

    constructor(
        private account: AccountApi,
        public wishlist: WishlistService,
        public cart: CartService,
        public url: UrlService,
        public loginService: LoginService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }
}
