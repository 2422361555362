import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router, Scroll, Event, UrlSerializer } from '@angular/router';

import { StatusCodeResponseService } from './services/status-code-response.service';

// modules (angular)
import { Location } from '@angular/common';
// modules (third-party)
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
// modules
import { AppRoutingModule, routes } from './app-routing.module';
import { CurrencyModule } from './modules/currency/currency.module';
import { RestApiModule } from './api';
import { HeaderModule } from './modules/header/header.module';
import { LanguageModule } from './modules/language/language.module';
import { MobileModule } from './modules/mobile/mobile.module';

// components
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';

// pages
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { FooterPargoModule } from './modules/footer-pargo/footer-pargo.module';
import { translateLoaderFactory } from './modules/language/translate-loader-factory';
import {
    HttpClient,
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi,
    withJsonpSupport,
} from '@angular/common/http';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { localizeLoaderFactory } from './modules/language/localize-loader-factory';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { MapService } from './services/map.service';
import { environment } from '../environments/environment';
import { BlockSpaceComponent } from './modules/shared/components/block-space/block-space.component';
import { VehiclePickerModalComponent } from './modules/shared/components/vehicle-picker-modal/vehicle-picker-modal.component';
import { LoginComponent } from './modules/shared/components/login/login.component';
import { CartComponent } from './modules/shared/components/cart/cart.component';
import { ScrollToTopButtonComponent } from './modules/shared/components/scroll-to-top-button/scroll-to-top-button.component';
import { LoadingBarComponent } from './modules/shared/components/loading-bar/loading-bar.component';
import { CustomUrlSerializer } from './core/custom-url-serializer';
import { NavigationService } from './services/navigation.service';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';


@NgModule({
    declarations: [
        // components
        AppComponent,
        RootComponent,
        // pages
        PageNotFoundComponent,
    ],
    providers: [
        { provide: APP_ID,  useValue: 'serverApp' },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        provideClientHydration(withNoHttpTransferCache()),
        provideHttpClient(withJsonpSupport(), withFetch(), withInterceptorsFromDi()),
        MapService,
        StatusCodeResponseService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleOAuthClientId ?? '',
                            { oneTapEnabled: false },
                        ),
                    },
                ],
                onError: (err: any) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    imports: [
        // modules (angular)
        ModalModule.forRoot(),
        ToastrModule.forRoot(),
        TranslateModule.forChild(),
        // modules
        AppRoutingModule,
        CurrencyModule.config({
            default: 'UAH',
            currencies: [
                {
                    symbol: '$',
                    name: 'US Dollar',
                    code: 'USD',
                    rate: 1,
                },
                {
                    symbol: '€',
                    name: 'Euro',
                    code: 'EUR',
                    rate: 1,
                },
                {
                    symbol: 'грн',
                    name: 'Гривна',
                    code: 'UAH',
                    rate: 1,
                    formatFn: (value) => {
                        // const digits = [].slice.call(value.toFixed(2)).reverse();
                        // const parts = [];

                        // while (digits.length) {
                        //     parts.push(digits.splice(0, 3).reverse().join(''));
                        // }

                        return `${value.toFixed(2)} грн`;
                    },
                },
            ],
        }),
        FooterPargoModule,
        HeaderModule,
        MobileModule,
        LanguageModule.config({
            default: 'uk',
            languages: [
                // {
                //     code: 'en',
                //     name: 'English',
                //     shortName: 'EN',
                //     image: 'assets/images/languages/language-1.png',
                //     direction: 'ltr',
                // },
                {
                    code: 'ru',
                    name: 'Russian',
                    shortName: 'RU',
                    image: 'assets/images/languages/language-2.png',
                    direction: 'ltr',
                },
                {
                    code: 'uk',
                    name: 'Українська',
                    shortName: 'UA',
                    image: 'assets/images/languages/language-2.png',
                    direction: 'ltr',
                },
            ],
        }),
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: localizeLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
            },
            initialNavigation: true,
            alwaysSetPrefix: false,
            useCachedLang: false,
            defaultLangFunction: () => 'uk',
        }),
        RestApiModule,
        CommonModule,
        BlockSpaceComponent,
        VehiclePickerModalComponent,
        LoginComponent,
        ScrollToTopButtonComponent,
        LoadingBarComponent,
        CartComponent,
    ],
})
export class AppModule {
    constructor(
        router: Router,
        viewportScroller: ViewportScroller,
        nav: NavigationService, //не убирать
        ) {
        router.events.pipe(
            filter((e: Event): e is Scroll => e instanceof Scroll),
        ).subscribe(e => {
            if (e.position) {
                viewportScroller.scrollToPosition(e.position);
            } else if (!e.anchor) {
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
