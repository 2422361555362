import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-item-option',
    templateUrl: './item-option.component.html',
    styleUrls: ['./item-option.component.scss'],
    standalone: true,
})
export class ItemOptionComponent {
    @Input() name = '';
    @Input() value: any;
}
