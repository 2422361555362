import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { finalize, map, take } from 'rxjs/operators';
import { CartData, CartItem, CartTotal, CheckoutApi, CheckoutUpdateItems } from '../api';
import { GtmService } from './gtm.service';
import { LoginService } from './login.service';
import { Router, NavigationStart } from '@angular/router';

export interface CartCorrectedData extends CartData {
    quantityOld: number;
    totalOld: number;
}

@Injectable({
    providedIn: 'root',
})
export class CartService implements OnDestroy {
    private data: CartData = {
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0,
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);

    private selectedItemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items.filter(item => item.selectForCheckout));
    private selectedQuantitySubject$: BehaviorSubject<number> = new BehaviorSubject(0);
    private selectedSubtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
    private selectedTotalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private selectedTotalSubject$: BehaviorSubject<number> = new BehaviorSubject(0);

    // private onAddingSubject$: Subject<Product> = new Subject();

    private destroy$: Subject<void> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get selectedItems(): ReadonlyArray<CartItem> {
        return this.selectedItemsSubject$.value;
    }

    get subtotal(): number {
        return this.data.subtotal;
    }

    get totals(): ReadonlyArray<CartTotal> {
        return this.data.totals;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    get total(): number {
        return this.data.total;
    }

    set noSelectUpdate(value: boolean | undefined) {
        this.data.noSelectUpdate = value;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();

    readonly selectedItems$: Observable<CartItem[]> = this.selectedItemsSubject$.asObservable();
    readonly selectedQuantity$: Observable<number> = this.selectedQuantitySubject$.asObservable();
    readonly selectedSubtotal$: Observable<number> = this.selectedSubtotalSubject$.asObservable();
    readonly selectedTotals$: Observable<CartTotal[]> = this.selectedTotalsSubject$.asObservable();
    readonly selectedTotal$: Observable<number> = this.selectedTotalSubject$.asObservable();

    // readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private gtm: GtmService,
        private login: LoginService,
        private api: CheckoutApi,
        private router: Router,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
            this.navigationListener();
            this.calcLocal();
            this.calc();

            this.login.signIn$.subscribe(() => {
                    if (!this.login.noSyncCart) {
                        this.api.cart(this.data)
                            .pipe(take(1))
                            .subscribe(data=> {
                                this.data.items = data.items;
                                this.calc();
                            })
                    }
                    this.noSelectUpdate = undefined;
            });

            this.login.signOut$.subscribe(() => {
                this.clearLocalCart();
            });
        }
    }

    productQuantity(productId: number): number {
        const item = this.items.find(value => value.product.id === productId);
        if (item) {
            return item.quantity;
        }
        return 0;
    }

    selectAllForCheckout() {
        this.itemsSubject$.value.forEach(value => value.selectForCheckout = true);

        this.calc();
        this.save();
    }

    correct(correctItemsData: CheckoutUpdateItems): CartCorrectedData {
        let items: CartItem[] = [];
        correctItemsData.items.forEach(value => {
            const item = this.items.find(eachItem => eachItem.product.id === value.Id);
            if (item) {
                item.product.priceOld = item.product.price;
                item.product.price = value.Price_1c;
                item.quantityOld = item.quantity;
                item.quantity = value.Amount_1c;

                items.push(item);
            }
        });

        const result: CartCorrectedData = {
            items: items,
            quantity: 0,
            quantityOld: this.selectedQuantitySubject$.value,
            totals: this.selectedTotalsSubject$.value,
            subtotal: this.selectedSubtotalSubject$.value,
            total: 0,
            totalOld: this.selectedTotalSubject$.value,
        }

        this.calcLocal();

        result.total = this.selectedTotalSubject$.value;
        result.quantity = this.selectedQuantitySubject$.value;

        this.save();

        return result;
    }

    add(product: Product, quantity: number, options: { name: string; value: string }[] = []): Observable<CartItem> {
        // this.onAddingSubject$.next(product);

        let item = this.items.find(eachItem => {
            if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                return false;
            }

            if (eachItem.options.length) {
                for (const option of options) {
                    if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                        return false;
                    }
                }
            }

            return true;
        });

        if (item) {
            this.gtm.addToCart({ items: [item] });
            item.quantity += quantity;
        } else {
            item = { product, quantity, quantityOld: 0, options, selectForCheckout: true };

            this.data.items.push(item);
            this.gtm.addToCart({ items: [item] });
        }

        if (this.login.isLoggedIn) {
            return this.api.addCartItem(item).pipe(
                take(1),
                finalize(() => {
                    this.calc();
                    this.save();
                }),
                map(() => item!),
            );
        } else {
            this.calc();
            this.save();
            return of(item);
        }
    }

    update(updates: { item: CartItem, quantity: number }[]): Observable<void> {
        updates.forEach(update => {
            const item = this.data.items.find(eachItem => eachItem === update.item);

            if (item) {
                item.quantity = update.quantity < 1 ? 1 : update.quantity;
                item.selectForCheckout = update.item.selectForCheckout;
            }
        });

        this.calc();
        return of(void 0);
    }

    remove(item: CartItem): Observable<void> {
        if (this.login.isLoggedIn) {
            return this.api.deleteCartItems([item]).pipe(
                take(1),
                map(() => {
                    this._remove(item);
                    return;
                }),
            );
        } else {
            this._remove(item);
            return of(void 0);
        }
    }

    private _remove(item: CartItem) {
        this.data.items = this.data.items.filter(eachItem => eachItem !== item);
        this.gtm.removeFromCart({ items: [item] });
        this.calc();
        this.save();
    }

    private calc(): void {
        let quantity = 0;
        let subtotal = 0;

        let selectedQuantity = 0;
        let selectedSubtotal = 0;

        this.api.calcCart(this.data.items)
            .pipe(take(1))
            .subscribe(value => {
                let existIds: number[] = [];
                for (const item of value.items) {
                    const productItem = this.data.items.find(e => e.product.id === item.id);
                    if (productItem) {
                        productItem.product.price = item.price;
                        productItem.quantity = item.quantity;
                        productItem.quantityOld = item.oldQuantity;
                        existIds = [...existIds, productItem.product.id];
                    }
                }

                for (const item of value.otherItems) {
                    const productItem = this.data.items.find(e => e.product.id === item.product.id);
                    if (productItem) {
                        productItem.product.price = item.product.price;
                        productItem.quantity = item.quantity;
                        productItem.quantityOld = item.quantityOld;
                        existIds = [...existIds, productItem.product.id];
                    } else {
                        this.data.items = [...this.data.items, item];
                        existIds = [...existIds, item.product.id];
                    }
                }

                this.data.items = this.data.items.filter(item => existIds.includes(item.product.id));

                quantity = value.quantity;
                subtotal = value.subtotal;

                const selectedItems = this.data.items.filter(item => item.selectForCheckout);

                selectedItems.forEach(item => {
                    selectedQuantity += item.quantity;
                    selectedSubtotal += item.product.price * item.quantity;
                });

                const totals: CartTotal[] = [];

                // totals.push({
                //     title: 'SHIPPING',
                //     price: 25,
                //     type: 'shipping',
                // });
                // totals.push({
                //     title: 'TAX',
                //     price: subtotal * 0.20,
                //     type: 'tax',
                // });

                const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

                const selectedTotal = selectedSubtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

                this.data.quantity = quantity;
                this.data.subtotal = subtotal;
                this.data.totals = totals;
                this.data.total = total;

                this.itemsSubject$.next(this.data.items);
                this.quantitySubject$.next(this.data.quantity);
                this.subtotalSubject$.next(this.data.subtotal);
                this.totalsSubject$.next(this.data.totals);
                this.totalSubject$.next(this.data.total);

                this.selectedItemsSubject$.next(selectedItems);
                this.selectedQuantitySubject$.next(selectedQuantity);
                this.selectedSubtotalSubject$.next(selectedSubtotal);
                this.selectedTotalsSubject$.next(totals);
                this.selectedTotalSubject$.next(selectedTotal);

                this.save();
            });
    }

    private calcLocal(): void {
        let quantity = 0;
        let subtotal = 0;

        let selectedQuantity = 0;
        let selectedSubtotal = 0;

        this.data.items.forEach(item => {
            quantity += item.quantity;
            subtotal += item.product.price * item.quantity;
        });

        const selectedItems = this.data.items.filter(item => item.selectForCheckout);

        selectedItems.forEach(item => {
            selectedQuantity += item.quantity;
            selectedSubtotal += item.product.price * item.quantity;
        });

        const totals: CartTotal[] = [];

        const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

        const selectedTotal = selectedSubtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);

        this.selectedItemsSubject$.next(selectedItems);
        this.selectedQuantitySubject$.next(selectedQuantity);
        this.selectedSubtotalSubject$.next(selectedSubtotal);
        this.selectedTotalsSubject$.next(totals);
        this.selectedTotalSubject$.next(selectedTotal);
    }

    private save(): void {
        localStorage.setItem('cartItems', JSON.stringify(this.data.items));
    }

    private load(): void {
        const items = localStorage.getItem('cartItems');

        if (items) {
            this.data.items = JSON.parse(items);
        }
    }

    clear(): void {
        this.api.deleteCartItems(this.data.items).pipe(take(1)).subscribe();
        this.data = {
            items: [],
            quantity: 0,
            subtotal: 0,
            totals: [],
            total: 0,
        };
        this.calc();
        this.save();
    }

    clearLocalCart() {
        this.data = {
            items: [],
            quantity: 0,
            subtotal: 0,
            totals: [],
            total: 0,
        };
        this.calcLocal();
        this.save();
    }

    clearSelected(): void {
        const needDelete = this.data.items.filter(item => item.selectForCheckout);
        this.data.items = this.data.items.filter(item => !item.selectForCheckout);
        this.calc();
        this.save();
        this.api.deleteCartItems(needDelete).pipe(take(1)).subscribe();
    }

    beginCheckout() {
        this.gtm.beginCheckout({ items: this.selectedItemsSubject$.value });
    }

    isVisible$ = new BehaviorSubject<boolean>(false);

    open(needCalc = true) {
        this.isVisible$.next(true);
        if (needCalc) {
            this.calc();
        }
    }

    close() {
        this.isVisible$.next(false);
    }

    private navigationListener() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.isVisible$.value) {
                    this.close();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
