<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">
    <div class="modal-dialog modal-dialog-centered vehicle-picker-modal">
        <div class="modal-content">
            <button type="button" class="vehicle-picker-modal__close" (click)="modal.hide()">
                <app-icon icon="cross-12"></app-icon>
            </button>
            <div
                class="vehicle-picker-modal__panel"
                [class.vehicle-picker-modal__panel--active]="true"
            >
                <div class="vehicle-picker-modal__title card-title">{{ 'HEADER_SELECT_VEHICLE'|translate }}</div>

                <div class="vehicle-picker-modal__text">
                    {{ 'TEXT_SELECT_VEHICLE_TO_FIND_EXACT_FIT_PARTS'|translate }}
                </div>

                <div class="vehicles-list">

                    <div class="vehicles-list__body">
                        <div
                            role="button"
                            *ngFor="let vehicle of garage.cars$|async"
                            class="vehicles-list__item"
                            [routerLink]="url.targetProducts(vehicle.targetKeyword)|localize"
                            (click)="close()"
                        >
                            <span class="vehicles-list__item-info">
                                <span class="vehicles-list__item-name">
                                    {{ vehicle.name }}
                                </span>
                                <app-item-option name="{{'VEHICLE_ENGINE'|translate}}"
                                                 value="{{vehicle.engines}}"></app-item-option>
                                <app-item-option name="{{'VEHICLE_MAKE_YEAR'|translate}}"
                                                 value="{{vehicle.years}}"></app-item-option>
                            </span>
                            <button
                                type="button"
                                class="vehicles-list__item-remove"
                                (click)="garage.removeCar(vehicle)"
                            >
                                <app-icon icon="recycle-bin-16"></app-icon>
                            </button>
                        </div>
                    </div>

<!--                    <div class="vehicles-list__body" appRadioGroup [formControl]="currentVehicleControl">-->
<!--                        <label class="vehicles-list__item">-->
<!--                            <app-radio-button class="vehicles-list__item-radio" [value]="null"></app-radio-button>-->
<!--                            <span class="vehicles-list__item-info">-->
<!--                                <span class="vehicles-list__item-name">-->
<!--                                    {{ 'TEXT_ALL_VEHICLES'|translate }}-->
<!--                                </span>-->
<!--                            </span>-->
<!--                        </label>-->
<!--                        <label *ngFor="let vehicle of garage.cars$|async" class="vehicles-list__item">-->
<!--                            <app-radio-button class="vehicles-list__item-radio" [value]="vehicle.targetKeyword"></app-radio-button>-->
<!--                            <span class="vehicles-list__item-info">-->
<!--                                <span class="vehicles-list__item-name">-->
<!--                                    {{ vehicle.name }}-->
<!--                                </span>-->
<!--                                <app-item-option name="{{'VEHICLE_ENGINE'|translate}}"-->
<!--                                                 value="{{vehicle.engines}}"></app-item-option>-->
<!--                                <app-item-option name="{{'VEHICLE_MAKE_YEAR'|translate}}"-->
<!--                                                 value="{{vehicle.years}}"></app-item-option>-->
<!--                            </span>-->
<!--                            <button-->
<!--                                type="button"-->
<!--                                class="vehicles-list__item-remove"-->
<!--                                (click)="garage.removeCar(vehicle)"-->
<!--                            >-->
<!--                                <app-icon icon="recycle-bin-16"></app-icon>-->
<!--                            </button>-->
<!--                        </label>-->
<!--                    </div>-->

                </div>

                <button
                    type="button"
                    class="btn btn-sm btn-secondary btn-block mt-2 vehicle-picker-modal__add-button"
                    (click)="close()"
                    [routerLink]="url.vehicle()|localize"
                >
                    {{ 'BUTTON_ADD_VEHICLE_LONG'|translate }}
                </button>

                <div class="vehicle-picker-modal__actions">
                    <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        (click)="close()"
                    >
                        {{ 'BUTTON_CANCEL'|translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
