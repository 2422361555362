<a [attr.href]="href|localize" class="indicator__button" (click)="onClick($event)">
    <span class="indicator__icon">
        <app-icon [icon]="icon"></app-icon>
        <span class="indicator__counter" *ngIf="counter !== undefined && counter !== null">{{ counter }}</span>
    </span>
    <div class="indicator__right">
        <span *ngIf="label" class="indicator__title">{{ label }}</span>
        <span *ngIf="value" class="indicator__value">{{ value }}</span>
    </div>
</a>
<div class="indicator__content">
    <ng-content></ng-content>
</div>
