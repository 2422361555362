import { Injectable } from '@angular/core';
import { AvailableOptions, CommonsApi } from '../base';
import { Observable } from 'rxjs';
import { Slide } from '../../interfaces/slide';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'projects/storefront/src/environments/environment';
import { Link } from '../../interfaces/link';
import { Category } from '../../interfaces/category';
import { Brand } from '../../interfaces/brand';
import { BaseVehicle, FeaturedCars } from '../../interfaces/vehicle';
import { CommonItn } from '../../interfaces/common-itn';

@Injectable()
export class RestCommonsApi extends CommonsApi {

    url = `${environment.apiHost}/common`;

    constructor(private http: HttpClient) {
        super();
    }

    getSlides(): Observable<Slide[]> {
        const url = `${this.url}/slides`;
        return this.http.get<Slide[]>(url);
    }

    getFile(slug: string): Observable<Link> {
        let params = new HttpParams().set('slug', slug);
        const url = `${this.url}/file`;
        return this.http.get<Link>(url, { params: params });
    }

    getAvailableCategories(options: AvailableOptions): Observable<Category[]> {
        const url = `${this.url}/available-categories`;
        let params = new HttpParams();
        if (options.brand) {
            params = params.set('brand', options.brand);
        }
        if(options.category) {
            params = params.set('category', options.category);
        }
        if (options.carBrand) {
            params = params.set('carBrand', options.carBrand);
        }
        if (options.carModel) {
            params = params.set('carModel', options.carModel);
        }
        return this.http.get<Category[]>(url, { params: params });
    }

    getBrand(slug: string): Observable<Brand> {
        const url = `${this.url}/brand`;
        const params = new HttpParams()
            .set('slug', slug);
        return this.http.get<Brand>(url, { params: params });
    }

    getAvailableVehicles(options: AvailableOptions): Observable<FeaturedCars> {
        const url = `${this.url}/available-vehicles`;
        let params = new HttpParams();
        if (options.brand) {
            params = params.set('brand', options.brand);
        }
        if (options.category) {
            params = params.set('category', options.category);
        }
        if (options.carBrand) {
            params = params.set('carBrand', options.carBrand);
        }
        return this.http.get<FeaturedCars>(url, { params: params });
    }

    getAvailableModels(options: AvailableOptions): Observable<BaseVehicle[]> {
        const url = `${this.url}/available-models`;
        let params = new HttpParams();
        if (options.brand) {
            params = params.set('brand', options.brand);
        }
        if (options.category) {
            params = params.set('category', options.category);
        }

        params = params.set('carBrand', options.carBrand!);

        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getFeaturedBrands(): Observable<Brand[]> {
        const url = `${this.url}/featured-brands`;
        return this.http.get<Brand[]>(url);
    }

    getAvailableBrands(options: AvailableOptions): Observable<Brand[]> {
        const url = `${this.url}/available-brands`;
        let params = new HttpParams();
        if (options.category) {
            params = params.set('category', options.category);
        }
        if (options.carBrand) {
            params = params.set('carBrand', options.carBrand);
        }
        if (options.carModel) {
            params = params.set('carModel', options.carModel);
        }
        return this.http.get<Brand[]>(url, { params: params });
    }

    getAllCars(): Observable<FeaturedCars> {
        const url = `${this.url}/cars`;
        return this.http.get<FeaturedCars>(url);
    }

    getPdv(itn: string): Observable<CommonItn> {
        const url = `${this.url}/company`;
        return this.http.get<CommonItn>(url, { params: { itn } });
    }
}
