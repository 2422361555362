import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CartService } from '../../../../services/cart.service';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';
// import { SignFormComponent } from '../sign-form/sign-form.component';
import { CartFormComponent } from '../cart-form/cart-form.component';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    standalone: true,
    imports: [
        ModalModule,
        IconComponent,
        CommonModule,
        CartFormComponent,
    ],
})
export class CartComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();

    @ViewChild('modal', { static: false }) modal?: ModalDirective;

    loadForm = false;

    constructor(public cartService: CartService) {}

    ngOnInit(): void {
        this.cartService.isVisible$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (value) {
                    this.open();
                } else {
                    if (this.modal?.isShown) {
                        this.modal?.hide();
                    }
                }
            });
    }

    open() {
        this.loadForm = true;
        this.modal?.show();
    }

    onClose() {
        this.loadForm = false;
        this.cartService.close();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
