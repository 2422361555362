import {
    Component,
    ElementRef,
    HostBinding,
    OnDestroy, OnInit,
    ViewChild,
} from '@angular/core';
import { MobileMenuService } from '../../../../services/mobile-menu.service';
import { VehiclePickerModalService } from '../../../../services/vehicle-picker-modal.service';
import { Observable, Subject } from 'rxjs';
import { CartService } from '../../../../services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { GarageService } from '../../../../services/garage.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UrlService } from '../../../../services/url.service';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    searchPlaceholder$!: Observable<string>;

    @HostBinding('class.mobile-header') classMobileHeader = true;

    @ViewChild('searchForm') searchForm!: ElementRef<HTMLElement>;

    @ViewChild('searchInput') searchInput!: ElementRef<HTMLElement>;

    constructor(
        private translate: TranslateService,
        public menu: MobileMenuService,
        public vehiclePicker: VehiclePickerModalService,
        public cart: CartService,
        public garage: GarageService,
        private router: Router,
        private localize: LocalizeRouterService,
        public url: UrlService,
    ) { }

    ngOnInit(): void {
        // this.searchPlaceholder$ = this.garage.currentVehicle$.pipe(
        //     switchMap(vehicle => {
        //         if (vehicle) {
        //             return this.translate.stream('INPUT_SEARCH_PLACEHOLDER_VEHICLE', vehicle);
        //         }
        //
        //         return this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
        //     }),
        // );
        this.searchPlaceholder$ = this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSearch(term: string) {
        //просьба снимать фильтр по машине при поиске
        // this.garage.setCurrentVehicle(null);

        if (term) {
            const route = this.localize.translateRoute(this.url.search(term));
            this.router.navigate([route as string]).then();
        }
    }

    openVehiclePicker(): void {
        // this.vehiclePicker.show(this.garage.currentVehicle);
        this.vehiclePicker.show();
    }
}
