<a routerLink="{{'/'|localize}}" class="logo">
    <div class="logo__slogan">
        {{ 'TEXT_SLOGAN'|translate }}
    </div>
    <div class="logo__image">
        <!-- logo -->
        <app-icon icon="logo"></app-icon>
        <!-- logo / end -->
    </div>
</a>
