import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// modules (third-party)
import { TranslateModule } from '@ngx-translate/core';

// components
import { ContactsPargoComponent } from './components/contacts/contacts-pargo.component';
import { FooterPargoComponent } from './footer-pargo.component';
import { LinksPargoComponent } from './components/links/links-pargo.component';
import { SocialComponent } from './components/social/social.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { IconComponent } from '../shared/components/icon/icon.component';

@NgModule({
    declarations: [
        // components
        ContactsPargoComponent,
        FooterPargoComponent,
        LinksPargoComponent,
        SocialComponent,
    ],
    exports: [
        // components
        FooterPargoComponent,
        ContactsPargoComponent,
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules (third-party)
        TranslateModule.forChild(),
        // modules
        LocalizeRouterModule,
        IconComponent,
    ],
})
export class FooterPargoModule { }
