import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// modules (third-party)
import { TranslateModule } from '@ngx-translate/core';
// modules
import { RadioModule } from '../radio/radio.module';

// components
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { HeaderComponent } from './header.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { LogoComponent } from './components/logo/logo.component';
import { SearchComponent } from './components/search/search.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { IconComponent } from '../shared/components/icon/icon.component';
import { GetProductImagePipe } from '../shared/pipes/get-product-image.pipe';
import { RemoveFromCartDirective } from '../shared/directives/remove-from-cart.directive';
import { CurrencyModule } from '../currency/currency.module';
import { ItemOptionComponent } from '../shared/components/item-option/item-option.component';
import { ClientsComponent } from '../shared/components/clients/clients.component';
import { LanguageSelectorComponent } from '../shared/components/language-selector/language-selector.component';

@NgModule({
    declarations: [
        // components
        AccountMenuComponent,
        ContactsComponent,
        DropcartComponent,
        HeaderComponent,
        IndicatorComponent,
        LogoComponent,
        SearchComponent,
        TopbarComponent,
    ],
    imports: [
        // modules (angular)
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // modules (third-party)
        TranslateModule.forChild(),
        // modules
        RadioModule,
        LocalizeRouterModule,
        IconComponent,
        GetProductImagePipe,
        RemoveFromCartDirective,
        CurrencyModule,
        ItemOptionComponent,
        ClientsComponent,
        LanguageSelectorComponent,
    ],
    exports: [
        HeaderComponent,
    ],
})
export class HeaderModule { }
