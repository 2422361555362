import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface BaseSEO {
    title: string;
    description: string;
    keywords: string[];
}

@Injectable({
    providedIn: 'root',
})
export class RouteMetaService {

    _noindexSet = false;

    constructor(
        private meta: Meta,
        private title: Title,
        private translate: TranslateService,
        private router: Router,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            // startWith(this.router),
        ).subscribe(() => {
            /**
             * сбрасываем установленный блок при переходе на другой маршрут
             */
            this.resetNoIndexBlock()
        });
    }

    resetNoIndexBlock() {
        this._noindexSet = false;
        this.meta.removeTag('name=\'robots\'');
    }

    update(data: BaseSEO) {
        this.title.setTitle(data.title ? data.title : 'ParGO');
        this.meta.updateTag({ name: 'description', content: data.description });
        this.meta.updateTag({ name: 'keywords', content: data.keywords.join(', ') });
    }

    noindex(set = true): void {
        /**
         * Блокировка выполнения процедуры добавления или удаления тега запрета индексации
         * Например, если на текущем маршруте уже был установлен ноиндекс, то его невозможно удалить
         */
        if (!this._noindexSet) {
            if (set) {
                this.meta.updateTag({ name: 'robots', content: 'noindex, follow' });
                this._noindexSet = true;
            } else {
                this.meta.removeTag('name=\'robots\'');
            }
        }
    }

    generateBuyTitle(h1: string) {
        return `${h1} | ${this.translate.instant('TEXT_BY_FROM')}`
    }

    generateTitle(h1: string, translate = true): string {
        return translate ? `${this.translate.instant(h1)} | ParGo` : `${h1} | ParGo`
    }
}
