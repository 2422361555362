import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AccountApi } from '../../base';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private account: AccountApi) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.endsWith('refresh-token')) {
            return next.handle(req);
        }

        if (this.account.user) {
            req = this.addToken(req, this.account.user.accessToken);
        }

        return next.handle(req).pipe(
            catchError((error: { status: number; }) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(req, next);
                }
                return throwError(() => error);
                // return EMPTY;
            }),
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.account.refreshToken().pipe(
                switchMap((newToken: string) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this.addToken(request, newToken));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next('UPDATE_FAILURE');
                    this.account.signOut();
                    const unauthorizedRequest = request.clone({
                        headers: request.headers.delete('Authorization'),
                    });
                    return next.handle(unauthorizedRequest);
                }),
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(jwt => jwt != null),
                take(1),
                switchMap(jwt => {
                    if (jwt === 'UPDATE_FAILURE') {
                        const unauthorizedRequest = request.clone({
                            headers: request.headers.delete('Authorization'),
                        });
                        return next.handle(unauthorizedRequest);
                    } else {
                        return next.handle(this.addToken(request, jwt));
                    }
                }),
            );
        }
    }

}
