<div class="modal" tabindex="-1" role="dialog" bsModal #modal="bs-modal"
     (onHidden)="onClose()"
>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header modal-login__header d-sm-none">
                <app-icon icon="logo" class="modal-login__header-icon"></app-icon>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.hide()">
                    <app-icon [icon]="'close-16'"></app-icon>
                </button>
            </div>
            <button type="button" class="modal-wrapper__close-button" (click)="modal.hide()">
                <app-icon [icon]="'close-16'"></app-icon>
            </button>
            <app-sign-form *ngIf="loadForm"></app-sign-form>
        </div>
    </div>
</div>
