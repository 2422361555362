<!-- <div class="mobile-menu__panel-header">
    <button *ngIf="level > 0" class="mobile-menu__panel-back" type="button" (click)="menu.closeCurrentPanel()">
        <app-icon icon="arrow-rounded-left-7x11"></app-icon>
       </button>
    <div class="mobile-menu__panel-title">{{ label }}</div>
    <a class="mobile-menu__panel-header__logo" routerLink="{{'/'|localize}}">
        <app-mobile-logo></app-mobile-logo>
    </a>
    <app-language-selector></app-language-selector>
</div> -->
<div class="mobile-menu__panel-body">
    <button *ngIf="level>0" class="mobile-menu__panel-back" type="button" (click)="menu.closeCurrentPanel()">
        <app-icon icon="arrow-rounded-left-7x11"></app-icon>
       </button>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <ng-content></ng-content>
</div>
