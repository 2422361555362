import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import {
    VehiclePickerModalService,
    VehiclePickerModalSession,
} from '../../../../services/vehicle-picker-modal.service';
import { takeUntil } from 'rxjs/operators';
import { UserVehicle } from '../../../../interfaces/vehicle';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { GarageService } from '../../../../services/garage.service';
import { UrlService } from '../../../../services/url.service';
import { LocalizeRouterModule, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router, RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { RadioModule } from '../../../radio/radio.module';
import { ItemOptionComponent } from '../item-option/item-option.component';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-vehicle-picker-modal',
    templateUrl: './vehicle-picker-modal.component.html',
    styleUrls: ['./vehicle-picker-modal.component.scss'],
    standalone: true,
    imports: [
        ModalModule,
        IconComponent,
        TranslateModule,
        RadioModule,
        ReactiveFormsModule,
        ItemOptionComponent,
        RouterLink,
        LocalizeRouterModule,
        CommonModule,
    ]
})
export class VehiclePickerModalComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject<void>();

    session: VehiclePickerModalSession|null = null;

    currentVehicleControl: UntypedFormControl = new UntypedFormControl(null);

    @ViewChild('modal') modal!: ModalDirective;

    constructor(
        private service: VehiclePickerModalService,
        public garage: GarageService,
        public url: UrlService,
        private localize: LocalizeRouterService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.currentVehicleControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(targetKeyword => {
                // this.garage.setCurrentVehicle(vehicleUId);
                // if (vehicleUId) {
                //     this.searchByCar();
                // }
                // this.select(this.garage.currentVehicle);
            });
    }

    searchByCar() {
        const route = this.localize.translateRoute(this.url.allProducts());
        this.router.navigateByUrl(route as string).then();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.service.show$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(session => {
            this.session = session;
            this.modal.show();
            this.currentVehicleControl.setValue(session.currentVehicle?.uid || null, { emitEvent: false });
        });
    }

    select(vehicle: UserVehicle|null): void {
        if (this.session) {
            this.session.select$.emit(vehicle);
        }

        this.close();
    }

    close(): void {
        if (this.session) {
            this.session.select$.complete();
            this.session.close$.emit();
            this.session.close$.complete();
            this.session = null;
        }

        this.modal.hide();
    }
}
